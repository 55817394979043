// App Configuration
export const DISCLAIMER = 'El contenido de este chat ha sido generado por un sistema de IA y puede contener errores, inconsistencias o información desactualizada.';
export const NOT_AUTH_USER = 'Tu usuario no tiene los permisos necesarios para utilizar esta aplicación. Por favor, ponte en contacto con el administrador para resolver este problema.';
export const VERSION = "VortexAI Chat v1.4.1.2 - Orq 0.2.3 - Modelo gpt-4o-ptu";
export const SIZE_CONVERSATION = 10;
export const AUTH_GROUP = 'SEAT_VortexAI_Authorized_Users';

export const ORCHESTATION_URL = 'https://vortexaiorchestrator.seat.vwgroup.com';

export const USER_GROUPS = {
  'SEAT_VortexAI_Context_Training': 
  {
    'index': 'formacion',
    'semantic_configuration': 'formacion-semantic-configuration'
  },
  'SEAT_VortexAI_Context_Sales_aftersales_and_marketing': 
  {
    'index': 'sales-aftersales-mkt',
    'semantic_configuration': 'sales-aftersales-mkt-semantic-configuration'
  },
	'SEAT_VortexAI_Context_Research_and_development': 
  {
    'index': 'research-development',
    'semantic_configuration': 'research-development-semantic-configuration'
  },
	'SEAT_VortexAI_Context_Purchasing': 
  {
    'index': 'purchasing',
    'semantic_configuration': 'purchasing-semantic-configuration'
  },
	'SEAT_VortexAI_Context_Production_and_logistics': 
  {
    'index': 'production-logistics',
    'semantic_configuration': 'production-logistics-semantic-configuration'
  },
	'SEAT_VortexAI_Context_People_and_organization': {
    'index': 'people-organization',
    'semantic_configuration': 'people-organization-semantic-configuration'
  },
	'SEAT_VortexAI_Context_IT': {
    'index': 'technology',
    'semantic_configuration': 'technology-semantic-configuration'
  },
	'SEAT_VortexAI_Context_Finance': {
    'index': 'finance',
    'semantic_configuration': 'finance-semantic-configuration'
  },
	'SEAT_VortexAI_Context_Central_Services': {
    'index': 'central-services',
    'semantic_configuration': 'central-services-semantic-configuration'
  },
  'SEAT_VortexAI_Context_Quality': {
    'index': 'quality',
    'semantic_configuration': 'quality-semantic-configuration'
  },
  'SEAT_VortexAI_Context_SGP': {
    'index': 'sgp',
    'semantic_configuration': 'sgp-semantic-configuration'
  },  
  'SEAT_VortexAI_Context_I+D-infotainment': {
    'index': 'infotaiment',
    'semantic_configuration': 'infotaiment-semantic-configuration'
  },  
    'SEAT_VortexAI_Context_proteccion-de-datos': {
    'index': 'proteccion-de-datos',
    'semantic_configuration': 'proteccion-de-datos-semantic-configuration'
  },  
  'SEAT_VortexAI_Context_Servicios-legales': {
    'index': 'servicios-legales',
    'semantic_configuration': 'servicios-legales-semantic-configuration'
  }, 
  'SEAT_VortexAI_Context_Presidencia': {
    'index': 'presidencia',
    'semantic_configuration': 'presidencia-semantic-configuration'
  },
  'SEAT_VortexAI_Context_Auditoria': {
    'index': 'auditoria',
    'semantic_configuration': 'auditoria-semantic-configuration'
  },
  'SEAT_VortexAI_Context_Baureihe': {
    'index': 'baureihe',
    'semantic_configuration': 'baureihe-semantic-configuration'
  },
  'SEAT_VortexAI_Context_GO-W1_PRL': {
    'index': 'go-w1-prl',
    'semantic_configuration': 'go-w1-prl-semantic-configuration'
  }    
};
 

export const API_DATA_SOURCES = {
  "type": "azure_search",
  "parameters": {
    "index_name": "finance",
    "embedding_dependency": {
        "type": "deployment_name",
        "deployment_name": "text-embedding-ada-002"
    },
    "fields_mapping": {},
    "in_scope": false,
    "semantic_configuration": "vector_semantic_hybrid",
    "strictness": 5,
    "top_n_documents": 4,
    "ephemeralId": null,
    "new_document": false
  }
};

export const CHAT_ROLE = {
    "type": "template",
    "content": "0"
}

export const LLM_MODEL = 'gpt-4o-ptu';

export const API_PARAMETERS = {
  "temperature": 0.1,
  "top_p": 1,
  "max_tokens": 1500,
  "stop": null,
  "stream": true
}

export const REQUEST_OPTIONS = {
  approach: 'rrr',
  overrides: {
    retrieval_mode: 'hybrid',
    semantic_ranker: true,
    semantic_captions: false,
    suggest_followup_questions: true,
  },
};


export const VORTEX_ID = 'a370f493-832e-4e12-bf1c-1a42af53708c';

